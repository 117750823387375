<template>
  <div>
    <!-- 支付是否成功start -->
    <mt-popup
      position="center"
      class="w90 pay-pop"
      v-model="payTypePop"
    >
      <div class="pay-type-box">
        <div class="tit">请确认微信支付是否完成</div>
        <div class="pay-btn btn" @click="$router.push({path: '/shopping/payment', query: {orderCode: orderCode, price: price}})">已完成支付</div>
        <div class="again-btn btn" @click="mallPay">支付遇到问题,重新支付</div>
        <div class="again-btn btn" @click="getPayTypePop">关闭</div>
      </div>
    </mt-popup>
    <!-- 支付是否成功start -->
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import {isWeiXin, onBridgeReadyStart } from "@/utils/common";
export default {
  props: {
    payTypePop: {
      type: Boolean,
      default: false,
    },
    orderCode: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  watch: {
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    // 支付
    mallPay() {
      this.postOrderLink({
        orderCode: this.orderCode,
        paySource: "WECHAT"
      }).then((res2)=> {
        if(isWeiXin()) {
          onBridgeReadyStart(JSON.parse(res2.data.payParam), (res3)=> {
            this.$router.push({
              path: '/shopping/payment', 
              query: {orderCode: this.orderCode, price: this.price}
            })
          }, ()=> {
            this.$toast("取消支付");
          });
        }
      })
    },
    ...mapActions("shopping", [
      "postOrderLink",
    ]),
    getPayTypePop(val) {
      this.$emit('changePayPop', false);
    },
  },
}
</script>
<style lang='less' scoped>
.pay-pop{
  border-radius: .1rem;
}
.pay-type-box{
  text-align: center;
  .tit{
    padding: .4rem 0;
    font-weight: bold;
    border-bottom: 1px solid #f7f7f7;
  }
  .pay-btn{
    padding: .2rem 0;
    color: #ff7141;
  }
  .btn{
    border-bottom: 1px solid #f7f7f7;
    &:last-child{
      border-bottom: none;
    }
  }
  .again-btn{
    padding: .2rem 0;
    color: #848484;
  }
}
</style>