<template>
  <div>
    <common-header></common-header>
    <div class="confirm-order-box warp">
      <!-- 地址 start -->
      <div class="address-box">
        <div class="l">
          <div class="">
            <span class="name">{{da.receiveName}}</span>
            <span class="phone">{{da.receivePhone}}</span>
          </div>
          <div class="address-ms">{{da.receiveRegionName}}{{da.receiveAddress}}</div>
        </div>
        <!-- <div class="icon-hui-arrow-right"></div> -->
      </div>
      <!-- 地址 end -->
    </div>
    <ul class="order-lists warp">
      <li class="item">
        <div class="time-type">
          <div class="time">{{da.createTime}}</div>
          <div class="type">{{orderStatus(da.status)}}</div>
        </div>
        <div class="commodity-ul">
          <div class="commodity-item" v-for="(item2, index2) in da.orderDetail" :key="index2">
            <img class="commodity-img" :src="noImgSet(item2.image)" alt="">
            <div class="right">
              <div class="commodity-details">
                <h4 class="name">{{item2.name}}</h4>
                <p class="commodity-ms">{{item2.specName}}{{item2.seckillId ? '(秒杀)' : ''}}</p>
              </div>
              <div class="commodity-num-box">
                <div class="num">x{{item2.quantity}}</div>
                <div class="money">
                  <span class="deposit" v-if="item2.deposit > 0">(含押金{{item2.deposit}}元)</span>
                  <span class="unit">￥</span>{{item2.price + item2.deposit}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 总计 start -->
        <div class="total-box" v-if="da.freightPrice > 0 || da.deductBalance > 0 || da.couponAmount > 0">
          <div class="item-max" v-if="da.couponAmount">
            <div class="il">优惠券</div>
            <div class="ir"><span class="unit">-￥</span>{{da.couponAmount}}</div>
          </div>
          <div class="item-max" v-if="da.deductBalance > 0">
            <div class="il">余额抵扣</div>
            <div class="ir">-￥{{da.deductBalance}}</div>
          </div>
          <div class="item-max" v-if="da.freightPrice > 0">
            <div class="il">配送费</div>
            <div class="ir">￥{{da.freightPrice}}</div>
          </div>
        </div>
        <div class="detailed">
          <span class="ms">实际支付<span class="shifu active-color">￥<b>{{da.realTotalPrice}}</b></span></span>
        </div>
        <!-- 总计 end -->
      </li>
    </ul>

    <!-- 订单信息 start -->
    <div class="warp">
      <div class="order-info">
        <div class="item" v-if="da.userRemark != ''">
          <span class="l">订单备注:</span>
          <span class="r">{{da.userRemark}}</span>
        </div>
        <div class="item">
          <span class="l">订单编号:</span>
          <span class="r">{{da.orderCode}}</span>
        </div>
        <div class="item" v-if="da.sendOutTime">
          <span class="l">发货时间:</span>
          <span class="r">{{da.sendOutTime}}</span>
        </div>
        <div class="item" v-if="da.expressCompany != ''">
          <span class="l">快递公司:</span>
          <span class="r">{{da.expressCompany}}</span>
        </div>
        <div class="item" v-if="da.expressCode != ''">
          <span class="l">快递单号:</span>
          <span class="r">{{da.expressCode}}</span>
        </div>
      </div>
    </div>
    <!-- 订单信息 end -->

    <!-- 支付是否成功start -->
    <PayPop :orderCode="da.orderCode" @changePayPop="changePayPop"  :payTypePop="payTypePop"></PayPop>
    <!-- 支付是否成功start -->

    <!-- 底部 start -->
    <div class="fixed-bottom-box" >
      <div class="fixed-bottom" v-if="isBottomShow(da.status)">
        <div class="h-btn btn" v-if="da.status == 100" @click="cancelOrder">取消订单</div>
        <div class="btn jb-r-l" v-if="da.status == 100 && (da.payStatus == 0 || da.payStatus == 3)" @click="mallPay(da.orderCode)">立即支付</div>
        <div class="apply-refund btn" v-if="da.status == 200" @click="applyRefund">申请退款</div>
        <div class="apply-refund btn" v-if="da.status == 201" @click="confirmReceipt">确认收货</div>
        <!-- <router-link to="/" class="btn jb-r-l">再去逛逛</router-link> -->
      </div>
    </div>
    <!-- 底部 end -->
  </div>
</template>
<script>
import PayPop from '@/components/global/PayPop'
import { mapActions } from 'vuex';
import { requestImgUrl, noImgSet, onBridgeReadyStart, isWeiXin} from "@/utils/common";
import { orderStatus } from "@/utils/utils";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.orderStatus = (e) => orderStatus(e);
    this.noImgSet = (e) => noImgSet(e);
    return {
      da: {},
      payTypePop: false,
    }
  },
  components:{
    'PayPop': PayPop
  },
  mounted() {
    this.init(this.$route.query.id);
  },
  methods: {
    // 判断底部是否显示
    isBottomShow(s) {
        // 订单状态; 100=已下单，200=待发货（已支付），201=已发货，202=确认收货，300=已取消，400=申请退款，401=退款驳回，402=退款中，403=退款成功，404=退款失败，500=已完成
  // 支付状态; 0=待支付，1=支付成功，2=支付失败，3=支付中
      let t = true;
      if (s == 202 || s == 300 || s >= 400) {
        t = false;
      }
      return t;
    },
    ...mapActions('account', [
      'getOrderDetails',
      "PutApplyRefund",
      'PutCancelOrder',
      'PutConfirmreceipt',
    ]),
    ...mapActions('shopping', [
      'postOrderLink',
    ]),
    changePayPop(e) {
      this.payTypePop = e;
    },
    // 确认收货
    confirmReceipt(e) {
      this.$MessageBox({
        title: '确认收到货了吗?',
        message: '为了保证您的售后权益,请收到商品检测无误后再确认收货',
        showCancelButton: true,
        confirmButtonText: "确认收货",
      }).then((res)=> {
        if (res == 'confirm') {
          this.PutConfirmreceipt({
            orderCode: this.da.orderCode,
          }).then((res)=> {
            this.init(this.da.id);
          })
        }
      })
    },
    // 初始化
    init(orderCode, isId) {
      let { query } = this.$route;
      this.getOrderDetails({
        id: orderCode,
        isId: query.isId && !isId? false : true,
      }).then((res)=> {
        this.da = res.data;
      })
    },
    // 支付
    mallPay(orderCode) {
      this.$Indicator.open({
        text: '检查支付环境...',
        spinnerType: 'snake',
      });
      this.postOrderLink({
        orderCode: orderCode,
        paySource: "WECHAT"
      }).then((res2)=> {
        if(isWeiXin()) {
          onBridgeReadyStart(JSON.parse(res2.data.payParam), (res3)=> {
            this.$router.push({
              path: '/shopping/payment', 
              query: {orderCode: orderCode, price: this.da.realTotalPrice}
            })
          }, ()=> {
            this.$toast("取消支付");
          });
        }
        this.$Indicator.close();
      })
    },
    // 申请退款
    applyRefund() {
      this.$MessageBox({
        title: '温馨提示',
        message: '是否申请退款?',
        showCancelButton: true
      }).then((e)=> {
        if (e == 'confirm') {
          this.PutApplyRefund({
            orderCode: this.da.orderCode,
          }).then((res)=> {
            this.init(this.da.id, true);
          })
        }
      })
      
    },
    // 取消订单
    cancelOrder() {
      this.$MessageBox({
        title: '温馨提示',
        message: '是否取消订单?',
        showCancelButton: true
      }).then((e)=> {
        if (e == 'confirm') {
          this.PutCancelOrder({
            orderCode: this.da.orderCode,
          }).then((res)=> {
            this.init(this.da.id);
          })
        }
      })
    },
  },
  
}
</script>
<style lang='less' scoped>
.deposit{
  font-size: .24rem;
  color: #bfbfbf;
  margin-right: .15rem;
}
.detailed{
  text-align: right;
  font-size: .28rem;
  color: #333;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #f7f7f7;
  padding: .25rem 0;
  .ms{
    margin-left: .2rem;
    display: flex;
    align-items: center;
  }
  .shifu{
    display: flex;
    align-items: center;
    b{
      font-size: .32rem;
      font-weight: normal;
    }
  }
}
.active-color{
  color: #ff7241;
}
.total-box{
  border-top: .02rem solid #f7f7f7;
  padding-top: .2rem;
  padding-bottom: .2rem;
  .copus-xx{
    display: flex;
    align-items: center;
    .ir{
      margin-right: .1rem;
    }
  }
  .item-max{
    font-size: .28rem;
    color: #333;
    display: flex;
    justify-content: space-between;
    height: .5rem;
    line-height: .5rem;
  }
  .price{
    margin-top: 0;
    margin-left: .1rem;
  }
}
.order-info{
  background: #fff;
  border-radius: .1rem;
  .item{
    padding: .2rem .28rem;
  }
  .l{
    font-size: .28rem;
    color: #262626;
    margin-right: .2rem;
  }
  .r{
    font-size: .28rem;
    color: #bfbfbf;
  }
}
.bottom{
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f7f7f7;
  .btn{
    width: 2.15rem;
    height: .65rem;
    border-radius: .32rem;
    color: #fff;
    text-align: center;
    line-height: .65rem;
    font-weight: 700;
  }
  .del{
    text-decoration: line-through;
  }
  .unit{
    font-size: .24rem;
  }
  .actual{
    color: #ff7241;
    margin-left: .12rem;
    .je{
      font-size: .32rem;
    }
  }
  .total{
    display: flex;
    font-size: .26rem;
    color: #727272;
    .l{
      margin-right: .2rem;
    }
  }
}
.goods-list{
  background: #fff;
  margin-top: .2rem;
  border-radius: .1rem;
  overflow: hidden;
  .li{
    display: flex;
    padding-bottom: .3rem;
    .goods-info{
      flex: 1;
      .name{
        color: #262626;
        font-size: .3rem;
        font-weight: 700;
      }
      .ms{
        font-size: .26rem;
        color: #bfbfbf;
        margin-top: .05rem;
      }
    }
  }
  .goods-img{
    width: 1.52rem;
    height: 1.52rem;
    border-radius: .05rem;
    margin-right: .5rem;
  }
}
.price{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .4rem;
  .num{
    font-size: .26rem;
    color: #727272;
  }
  .unit{
    font-size: .24rem;
  }
  .money{
    font-size: .32rem;
    color: #ff7241;
    font-weight: 700;
  }
}
.order-lists{
  margin-top: .2rem;
  .item{
    padding-top: .46rem;
    padding-left: .36rem;
    padding-right: .36rem;
    background: #fff;
    border-radius: .1rem;
    margin-bottom: .2rem;
  }
}
.time-type{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .time{
    font-size: .24rem;
    color: #bfbfbf;
  }
  .type{
    font-size: .24rem;
    color: #ff7241;
  }
}
.order-type-box{
  background: #fff;
  display: flex;
  justify-content: space-between;
  height: 1.13rem;
  align-items: center;
  line-height: 1.13rem;
  font-size: .3rem;
  color: #262626;
  .active{
    color: #ff7241;
    font-weight: 700;
    border-bottom: .04rem solid #ff7241;
  }
}
.fixed-bottom-box{
  height: 1.13rem;
  margin-top: .2rem;
}
.fixed-bottom{
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 1.13rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: .5rem;
  padding-right: .38rem;
  .btn{
    margin-left: .32rem;
  }
  .apply-refund{
    border: 1px solid #ff7241;
    color: #ff7241;
  }
}


.address-box{
  display: flex;
  background: #fff;
  align-items: center;
  border-radius: .1rem;
  padding: .3rem .2rem ;
  .address-ms{
    color: #727272;
    font-size: .28rem;
    padding-right: .4rem;
    margin-top: .2rem;
  }
  .name{
    color: #262626;
    font-size: .32rem;
    margin-right: .2rem ;
  }
  .phone{
    color: #262626;
    font-size: .32rem;
  }
  .l{
    flex: 1;
  }
}
.confirm-order-box{
  padding-top: .5rem;
  background:url('../../assets/images/img/car-bj.png') no-repeat;
  background-size: 100%;
  min-height: 2rem;
  background-size: 100%;
  background-position-y: -.2rem;
}

</style>